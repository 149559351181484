import * as React from 'react';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import TopicIcon from '@mui/icons-material/Topic';
import DeleteIcon from '@mui/icons-material/Delete';
import InventoryIcon from '@mui/icons-material/Inventory';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import UndoIcon from '@mui/icons-material/Undo';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const MaterialIcon = ({ type, size }) => {
    if (type === 'followup') {
        return <RecordVoiceOverRoundedIcon fontSize={size} />
    } else if (type === 'play'){
        return <PlayArrowIcon fontSize={size} />
    } else if (type === "infinite") {
        return <AllInclusiveIcon fontSize={size} />
    } else if (type === 'backlog') {
        return <UndoIcon fontSize={size} />
    } else if (type === 'main') {
        return <ListAltIcon fontSize={size} />
    } else if (type === 'delete') {
        return <DeleteIcon fontSize={size} />
    } else if (type === 'archive') {
        return <InventoryIcon fontSize={size} />
    } else if (type === 'current') {
        return <PlaylistAddCheckIcon fontSize={size} />
    } else if (type === 'nextdate') {
        return <EventRepeatIcon fontSize={size} />
    } else if (type === 'time') {
        return <AccessTimeIcon fontSize={size} />
    }
}

export default MaterialIcon
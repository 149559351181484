const audio = new Audio('https://www2.cs.uic.edu/~i101/SoundFiles/PinkPanther30.wav');
const notification = new Audio('https://www.mmsp.ece.mcgill.ca/Documents/AudioFormats/WAVE/Samples/SoundCardAttrition/stereol.wav');

function playNotification() {
  notification.play();
}

function pauseNotification() {
  notification.pause();
}

function playSound() {
  audio.play();
}

function pauseSound() {
  audio.pause();
}

export {
    playSound,
    pauseSound,
    playNotification,
    pauseNotification
}
import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Network from './network_service';

import { useState } from 'react';
import { useDeepEffect, useDebounce, getLocalStore } from './util';
import MainPage from './pages/MainPage';
import Master from './pages/Master';
import Month from './pages/Month';
import Week from './pages/Week';
import Archive from './pages/Archive';
import Someday from './pages/Someday';
import Timer from './pages/Timer';

const Routing = () => {
  const { persist, getPersist } = getLocalStore();
  const localStore = getPersist();
  const [list, setList] = useState([]);
  const [listMap, setListMap] = useState({});
  const [group, setGroup] = useState({});
  const [postApi, setPostApi] = useState({});
  const [timerOn, setTimerOn] = useState(null);
  const [minimize, setMinimize] = useState({});
  const [hideDetail, setHideDetail] = useState(localStore.hideDetail || {});
  const [pageDate, setPageDate] = React.useState(new Date().toISOString());
  const [showForm, setShowForm] = useState(false);

  const debounceList = useDebounce(list, 1000);

  React.useEffect(() => {
    getList();
  }, [pageDate]);

  const changeDate = (ch) => {
    let d = new Date(pageDate);
    if (ch === 'next') {
      d.setDate(d.getDate() + 1);
      setPageDate(d);
    } else if (ch === 'prev') {
      d.setDate(d.getDate() - 1);
      setPageDate(d);
    } else {
      d = new Date();
      setPageDate(d);
    }
  }

  const computeTaskMap = (list) => {
    const taskToIndex = {};
    list.forEach((task, idx) => {
      taskToIndex[task.id] = idx;
    });
    setListMap(taskToIndex);
  }

  const getList = async () => {
    const response = await Network.getListFromBackend();
    if (response) {
      setList(response);
      computeTaskMap(response);
    }
  }

  const saveList = async (newListResp) => {
    setPostApi({ loading: true });
    const response = await Network.postListToBackend(newListResp);
    if (response.status === 200) {
      setPostApi({ loading: false, success: true });
    } else {
      setPostApi({ loading: false, success: false });
    }
    setTimeout(() => { setPostApi({}) }, 2000);
  }

  useDeepEffect(() => {
    if (debounceList && debounceList.length > 0) {
      saveList(debounceList);
    }
  }, [debounceList])

  const setItem = (id, thisItemProps) => {
    const taskIndex = listMap[id];
    if(typeof taskIndex === 'number') {
      const newList = [...list];
      newList[taskIndex] = { ...newList[taskIndex], ...thisItemProps };
      setList(newList);
    }
  }

  const addTaskToList = (task) => {
    setList([...list, task]);
    computeTaskMap(list);
  }

  const deleteItem = (id) => {
    setItem(id, { archived: true });
  }

  const minimizeList = (event) => {
    setMinimize({ ...minimize, [event.target.id]: !minimize[event.target.id] });
  }

  const hideListDetail = (event, id) => {
    event.stopPropagation();
    persist({ ...localStore, hideDetail: { ...hideDetail, [id]: !hideDetail[id] } });
    setHideDetail({ ...hideDetail, [id]: !hideDetail[id] });
  }

  const groupTask = (event, id) => {
    event.stopPropagation();
    setGroup({ ...group, [id]: !group[id] });
  }

  const props = {
    list,
    setList,
    group,
    setGroup,
    postApi,
    setPostApi,
    timerOn,
    setTimerOn,
    minimize,
    setMinimize,
    hideDetail,
    setHideDetail,
    addTaskToList,
    deleteItem,
    setItem,
    minimizeList,
    hideListDetail,
    groupTask,
    saveList,
    getList,
    pageDate,
    changeDate,
    showForm,
    setShowForm,
  }

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact default path="/" element={<MainPage {...props} />} />
        </Routes>
        <Routes>
          <Route exact default path="/master-list" element={<Master {...props} />} />
        </Routes>
        <Routes>
          <Route exact default path="/month-list/:month" element={<Month {...props} />} />
        </Routes>
        <Routes>
          <Route exact default path="/week-list/:week" element={<Week {...props} />} />
        </Routes>
        <Routes>
          <Route exact default path="/archived-list" element={<Archive {...props} />} />
        </Routes>
        <Routes>
          <Route exact default path="/someday-list" element={<Someday {...props} />} />
        </Routes>
        <Routes>
          <Route exact default path="/timer" element={<Timer {...props} />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default Routing;
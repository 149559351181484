import styled, { css } from 'styled-components';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import useAutosizeTextArea from './useAutoResize';
import { v4 as uuidv4 } from 'uuid';
import majorIcon from './priority_icons/major.svg';
import highIcon from './priority_icons/high.svg';
import mediumIcon from './priority_icons/medium.svg';
import lowIcon from './priority_icons/low.svg';
import minorIcon from './priority_icons/minor.svg';
import blockerIcon from './priority_icons/blocker.svg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useDeepEffect, getTaskPageType, ITEM_TYPE, pageType, isPage } from './util';

String.prototype.replaceAt = function (index, replacement) {
  return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

const TimeDiv = styled.div`
    color: ${props => props.color ? props.color : 'black'};
    background-color: ${props => props.bg ? props.bg : 'yellow'};
    padding: 2px;
    margin: 2px;
    font-family: monospace;
    border: 1.9px solid ${props => props.bd ? props.bd : 'white'};
    font-weight: 600;
    text-align: center;
    width: 105px;
    min-height: 14px;
    font-size: 11px;
`;

const MyButton = styled.button`
  margin: 3px;
  max-height: 35px;
  padding: 0.3rem 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${props => props.bg ? props.bg : '#FFFFFF'};
  border: 0;
  border-radius: .5rem;
  box-sizing: border-box;
  // color: #111827;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
  text-decoration: none #D1D5DB solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  color: ${props => props.color ? props.color : 'black'};

  &:hover {
    background-color: grey;
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &:focus-visible {
    box-shadow: none;
  }
`;

const CustomButton = ({ id, color, bg, variant, minWidth, maxWidth, text, size, onClick }) => {
  return <MyButton
    id={id}
    bg={bg}
    style={{ margin: '3px', minWidth: minWidth || '35px', maxWidth: maxWidth || '35px' }}
    onClick={onClick}
    color={color}
    variant={variant ? variant : "contained"}
    size={size ? size : "small"}>
    {text}
  </MyButton>
};

const FlexBox = styled.div`
    display: flex;
    flex-direction: ${props => props.fd ? props.fd : 'column'};

    ${(props) =>
    props.enableMediaQuery
      ? css`
          @media (max-width: 600px) {
            flex-direction: column;
          }
          `
      : ''};

    
`

const TimeBox = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    #timer-head {
        white-space: pre-wrap;
    }
    .PrivateSwitchBase-root {
      color: white;
      padding: 4px;
    }
    margin-left: 15%
`;

const ListTextBlock = styled(FlexBox)`
    margin-top: 2px;
    margin-left: 6px;
    font-weight: 600;
    justify-content: flex-start;
    font-family: "Gill Sans", sans-serif;
    width: 85%;
`;

const ItemContainer = styled(FlexBox)`
    color: black;
    padding: 3px;
    margin: 8px 6px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.bg ? props.bg : 'white'};
    background-color: ${(props) => props.bc ? props.bc : '#edf2da'};
    .MuiButtonBase-root {
        padding: 0px;
    }
    position: relative;
`

const ListHeader = styled.div`
    font-weight: 600;
    text-align: center;
    padding: 4px;
    margin: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: ${props => props.jc ? props.jc : 'space-between'};
    background-color: ${props => props.bg ? props.bg : 'lightgreen'};
    color: ${props => props.color ? props.color : 'black'};
    border-radius: 12px;
    position: relative;
`;

const ListContainer = styled.div`
    min-width: 30%;
    display: ${props => props.hide ? 'none' : 'block'};  
    margin-bottom: 50px;
    width: 100%;
`;

const MasterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 600px;
    width: 100%;
    > div {
        flex: 1 1 22%;
        max-height: 200px;
        overflow-y: scroll;
    }  
`;

const MinimizeView = styled(FlexBox)`
    position: fixed;
    bottom: ${({ bottom }) => bottom ? bottom : '0px'};
    right: ${({ right }) => right ? right : 'none'};
    width: 98vw;
    display: flex;
    flex-direction: row;
    justify-content: end;
    background-color: #4b0000;
    padding: 10px 20px;
`;

const IconWrapper = styled.span`
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 8px;
`;

const GroupIconWrapper = styled.span`
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    left: 5px;
    top: 8px;
`;

const lightOrDark = (color) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  return brightness > 155 ? 'black' : 'white';
}

const PriorityBlock = styled.span`
    background-color: ${props => props.bg ? props.bg : '#bba7ec'};  
    color: ${props => props.bg ? lightOrDark(props.bg) : 'black'};  
    border-radius: 5px;
    padding: 2px 5px;
    width: fit-content;
    margin-left: 6px;
    margin-top: 4px;
    font-size: 12px;
`;

const PriorityImg = styled.img`
  width: 23px;
  height: 23px;
  background-color: transparent;
  margin-top: 0px;
`;

const PriorityItem = ({ wt, onClick }) => {
  if (wt <= 1) return <PriorityImg onClick={onClick} src={minorIcon} />
  else if (wt <= 3) return <PriorityImg onClick={onClick} src={lowIcon} />
  else if (wt <= 5) return <PriorityImg onClick={onClick} src={mediumIcon} />
  else if (wt <= 7) return <PriorityImg onClick={onClick} src={highIcon} />
  else if (wt <= 9) return <PriorityImg onClick={onClick} src={majorIcon} />
  else if (wt == 10) return <PriorityImg onClick={onClick} src={blockerIcon} />
  else return <ImpactIcon onClick={onClick}>NA</ImpactIcon>
}

const ImpactIcon = styled.span`
  font-size: 12px;
  color: white;
  padding: 2px 5px;
  margin: -3px;
  background-color: green;
  border-radius: 4px;
`;

const ImpactItem = ({ wt, onClick }) => {
  if (wt <= 1) return <ImpactIcon onClick={onClick}>ZERO</ImpactIcon>
  else if (wt <= 2) return <ImpactIcon onClick={onClick}>SHORT</ImpactIcon>
  else if (wt <= 3) return <ImpactIcon onClick={onClick}>LONG</ImpactIcon>
  else if (wt <= 4) return <ImpactIcon onClick={onClick}>LEAP</ImpactIcon>
  else if (wt <= 5) return <ImpactIcon onClick={onClick}>URGENT</ImpactIcon>
  else if (wt <= 6) return <ImpactIcon onClick={onClick}>BLOCKED</ImpactIcon>
  else return <ImpactIcon onClick={onClick}>NA</ImpactIcon>
}

const TextArea = styled.textarea`
    width: ${props => props.width ? props.width : '100%'};
    height: 130px;
`;

const DateSelectorItem = styled.div`
    margin-top: 15px;
    font-size: 12px !important;
    min-width: 165px;
    input {
        font-size: 12px !important;
        padding: 8px 2px;
    }
`;

const DoubleClickDateChange = ({ color, bg, width, value, icon, label, labelStyle, setValue }) => {
  const time = moment(value).format("hh:mm A");
  const date = moment(value).format("DD MMM");

  const [toggle, setToggle] = useState(true);
  const textAreaRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
        setToggle(true);
      }
    }
    document.addEventListener("dblclick", handleClickOutside);
  }, [])

  useAutosizeTextArea(textAreaRef.current, value, toggle);

  // useEffect(() => {
  //     if (!toggle) {
  //         setValue(value);
  //     }
  // }, [toggle])

  function toggleInput() {
    setToggle(false);
  }

  function handleChange(event) {
    setValue(event.toISOString());
  }

  if (toggle) {
    if (!value) {
      return <TimeDiv color={'#e700ff'} bg={'white'} onClick={toggleInput}>
        <div>{`${label} ?`}</div>
      </TimeDiv>
    }
    return <TimeDiv color={color} bg={bg} onClick={toggleInput}>
      <div>{date} {time}</div>
    </TimeDiv>
  }
  return <DateSelectorItem onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
  }}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        inputRef={textAreaRef}
        label={`${label} Deadline`}
        value={value}
        onChange={(e) => handleChange(e)}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  </DateSelectorItem>
}

const checkMark = '[ch]';
const uncheckMark = '[uc]';
const addCheckMarks = (mark, text) => {
  const splits = text.split(mark);
  const furtherSplits = splits.reduce((acc, v, idx) => {
    if (v.includes(uncheckMark)) {
      const uS = v.split(uncheckMark);
      return [...acc, ...uS];
    }
    return [...acc, v];
  }, []);
  return furtherSplits;
}

const checkMarkerMap = (text) => {
  let m = [];
  for (let i = 0; i < text.length; i++) {
    if (text.substring(i, i + 4) === checkMark) {
      m.push({ checked: true, index: i });
    } else if (text.substring(i, i + 4) === uncheckMark) {
      m.push({ checked: false, index: i });
    }
  }
  return m;
}


const subTodo = (text, setValue) => {
  const replaceText = (idx) => {
    if (text.substring(idx, idx + 4) === checkMark) {
      setValue(text.replaceAt(idx, uncheckMark));
    } else {
      setValue(text.replaceAt(idx, checkMark));
    }
  }

  if (!text) {
    return text;
  } else if (text.includes(checkMark) || text.includes(uncheckMark)) {
    const includeChecks = addCheckMarks(checkMark, text);
    const m = checkMarkerMap(text);
    return includeChecks.reduce((acc, v, idx) => {
      if (idx === includeChecks.length - 1)
        return [...acc, v];
      return [...acc, v, <CustomCheckbox size="small" checked={m[idx].checked} setChecked={() => {
        replaceText(m[idx].index)
      }} />]
    }, []);
  } else {
    return text;
  }
}

const CustomCheckbox = ({ size, checked, setChecked }) => {
  return <Checkbox
    size={size}
    checked={checked}
    onChange={(idx) => {
      setChecked(idx)
    }}
    inputProps={{ 'aria-label': 'controlled' }}
  />
}

const DoubleClickEditField = ({ type, unit, width, value, placeholder, icon, label, labelStyle, setValue }) => {
  const [toggle, setToggle] = useState(true);
  const [text, setText] = useState(value);
  const textAreaRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
        setToggle(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [])

  // useAutosizeTextArea(textAreaRef.current, text || value, toggle);

  useEffect(() => {
    if (toggle && text) {
      setValue(text);
    }
  }, [toggle])

  function toggleInput() {
    setToggle(false);
  }

  function onKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation()
      let val = event.target.value;
      let start = event.target.selectionStart;
      let end = event.target.selectionEnd;
      event.target.value = val.substring(0, start) + '\t' + val.substring(end);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
      setText(event.target.value);
    }
  }

  function handleChange(event) {
    if (unit === 'number')
      setText(Number(event.target.value));
    setText(event.target.value);
  }

  function setter(value) {
    setValue(value);
    setText(value);
  }

  if (toggle) {
    if (type === ITEM_TYPE.TIME_FRAME) {
      return <MyButton bg={'transparent'} style={labelStyle} onDoubleClick={toggleInput}>{label}{icon}</MyButton>
    }
    return <span style={labelStyle} onDoubleClick={toggleInput}>{icon}{label}{text ? subTodo(text, setter) : placeholder}</span>
  }
  return <TextArea
    width={width}
    type="text"
    value={text}
    ref={textAreaRef}
    rows={1}
    onChange={handleChange}
    onKeyDown={onKeyDown}
  />
}

const DoubleClickDropDown = ({ inputs, type, value, setValue }) => {
  const [toggle, setToggle] = useState(true);
  const dropDownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setToggle(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [])

  useEffect(() => {
    if (!toggle) {
      setValue(value);
    }
  }, [toggle])

  function toggleInput() {
    setToggle(!toggle);
  }

  function handleChange(event) {
    if (type === 'number')
      setValue(Number(event.target.value));
    setValue(event.target.value);
  }

  if (toggle) {
    if (type === ITEM_TYPE.PRIORITY) {
      return <MyButton bg={'transparent'} onClick={toggleInput}><PriorityItem wt={value} /></MyButton>
    } else if (type === ITEM_TYPE.ORDER) {
      return <CustomButton bg="transparent" size="large" variant="text" color="secondary" onClick={toggleInput} text={value || '?'} />
    } else {
      return <span onClick={toggleInput}>{value}</span>
    }
  }
  return <select defaultValue={value} ref={dropDownRef} name="select" onChange={handleChange}>
    {inputs.map(function (n) {
      return (<option value={n} selected={value === n}>{n}</option>);
    })}
  </select>
}

const ImpactDropDown = ({ type, value, setValue }) => {
  const [toggle, setToggle] = useState(true);
  const dropDownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setToggle(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [])

  useEffect(() => {
    if (!toggle) {
      setValue(value);
    }
  }, [toggle])

  function toggleInput() {
    setToggle(!toggle);
  }

  function handleChange(event) {
    if (type === 'number')
      setValue(Number(event.target.value));
    setValue(event.target.value);
  }

  if (toggle) {
    return <MyButton bg={'transparent'} onClick={toggleInput}><ImpactItem wt={value} /> </MyButton>
  }
  const impacts = [0, 1, 2, 3, 4, 5, 6];
  return <select defaultValue={value} ref={dropDownRef} name="select" onChange={handleChange}>
    {impacts.map(function (n) {
      return (<option value={n} selected={value === n}>{n}</option>);
    })}
  </select>
}

const Hr = styled.hr`
    border: 1px dashed #736f6f;
    margin-left: 0px;
    width: 95%;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const AlignButton = styled(FlexBox)`
    right: 4px;
    position: absolute;
`

const AlignSchedule = styled(FlexBox)`
    margin-right: 35px!important;
`

const taskSchema = {
  "timeFrame": 1,
  "follow": false,
  "checked": false,
  "current": false,
  "tempDeadline": null,
  "finalDeadline": null,
  "weightage": 0,
  "backlog": true,
  "followUpName": null,
  "completedAt": null,
  "pageType": null
}

const FormContainer = styled(FlexBox)`
    margin: auto;
    width: 600px;
    padding: 10px 20px;
    background-color: tan;
    margin-top: 10%;

    @media (max-width: 600px) {
      width: 350px;
    }

    input {
        height: 30px;
        margin-top: 10px;
        padding: 2px;
    }
`;

const CreateTaskForm = ({ onSave, showForm, setShowForm }) => {
  const [task, setTask] = useState(taskSchema);

  const [errorObj, setError] = useState({ error: false });

  useDeepEffect(() => {
    if (!task.id) {
      const id = uuidv4();
      setTask({ ...task, id });
    }

    if (!task.page) {
      setTask({ page: getTaskPageType() });
    }
  }, [task])

  const handleChange = (fieldName, event) => {
    if (fieldName === 'tempDeadline' || fieldName === 'finalDeadline') {
      setTask({ ...task, [fieldName]: event.toISOString() });
    } else {
      setTask({ ...task, [fieldName]: event.target.value });
    }
    setError({ ...errorObj, error: false });
  }

  const onCreate = (task) => {
    if (isPage(pageType.SOMEDAY) && (!task.name)) {
      setError({ ...errorObj, error: true });
    } else if (!isPage(pageType.SOMEDAY) && (!task.name || !task.timeFrame)) {
      setError({ ...errorObj, error: true });
    } else {
      onSave(task);
      setTask(taskSchema);
    }
  }

  return <Modal
    open={showForm}
    onClose={() => setShowForm(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <FormContainer fd="column">
      <div style={{ fontWeight: 700, color: 'black' }}>Task Form</div>
      <input id="name" placeholder="Task name" value={task['name']} onChange={(e) => handleChange('name', e)} />
      {!isPage(pageType.SOMEDAY) &&
        <>
          <input id="weightage" placeholder="Task priority" value={task['weightage']} onChange={(e) => handleChange('weightage', e)} />
          <input id="timeFrame" placeholder="Time frame" value={task['timeFrame']} onChange={(e) => handleChange('timeFrame', e)} />
          <input id="parentTag" placeholder="Parent task tag" value={task['parentTag']} onChange={(e) => handleChange('parentTag', e)} />
          <input id="page" placeholder="Page type" value={task['page']} onChange={(e) => handleChange('page', e)} />
          <Spacer space="15px" />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={`Temporary Deadline`}
              value={task['tempDeadline'] ? task['tempDeadline'] : null}
              onChange={(e) => handleChange('tempDeadline', e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Spacer space="15px" />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={`Final Deadline`}
              value={task['finalDeadline'] ? task['finalDeadline'] : null}
              onChange={(e) => handleChange('finalDeadline', e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Spacer space="10px" />
        </>
      }
      {errorObj.error ? <CustomButton maxWidth="100%" variant="outline" color="error" text="Some fields are invalid!" /> : null}
      <FlexBox fd="row">
        <CustomButton onClick={() => onCreate(task)} text="Create Task" maxWidth="130px" />
        <CustomButton onClick={() => setShowForm(false)} text="Close" maxWidth="130px" />
      </FlexBox>
    </FormContainer>
  </Modal>
}

const VerticalLine = styled.div`
    border-left: 20px solid ${({ color }) => color || 'green'};
    height: 125%;
    z-index: 1;
    margin-right: 2px;
    margin-top: -14px;
    margin-left: -5px;
`;

const TaskHeading = styled.div`
    background-color: ${props => props.bg ? props.bg : '#bba7ec'};  
    color: ${props => props.bg ? lightOrDark(props.bg) : 'black'};
    text-align: center;
    font-weight: 700;
    margin: -5px -5px 0px -5px;
    padding: 5px;
`;

const Spacer = styled.div`
    margin-top: ${props => props.space ? props.space : '0px'};
`;

const VerticalSpacer = styled.div`
    margin-left: ${props => props.space ? props.space : '0px'};
    margin-right: ${props => props.space ? props.space : '0px'};
`;

const getReadableTimeFrame = (timeFrame) => {
  const readableHours = Math.floor(timeFrame / 60) > 0 ? `${Math.floor(timeFrame / 60)} hr` : '';
  const readableMinutes = (timeFrame % 60) > 0 ? ` ${timeFrame % 60} min` : '';
  const readableTimeFrame = `${readableHours}${readableMinutes}`;
  return readableTimeFrame;
}

const TimeBlock = styled.div`
    padding: 15px;
    background-color: white;
    color: black;
    font-size: 25px;
    width: 100px;
    text-align: center;
`;

export {
  TimeDiv,
  FlexBox,
  CustomButton,
  ItemContainer,
  ListHeader,
  ListContainer,
  MinimizeView,
  ListTextBlock,
  IconWrapper,
  PriorityBlock,
  DoubleClickEditField,
  TextArea,
  Hr,
  AlignButton,
  AlignSchedule,
  CreateTaskForm,
  GroupIconWrapper,
  VerticalLine,
  PriorityItem,
  TaskHeading,
  Spacer,
  getReadableTimeFrame,
  DoubleClickDropDown,
  TimeBlock,
  TimeBox,
  DoubleClickDateChange,
  MasterContainer,
  ImpactDropDown,
  VerticalSpacer
}
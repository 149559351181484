import * as React from 'react';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SocialDistance from '@mui/icons-material/SocialDistance';
import { CustomButton } from '../styles';

const CollapseIcon = ({ id, open, onClick }) => {
  if (open)
    return <CustomButton float="right" onClick={(e) => onClick(e, id)} text={<UnfoldLessIcon color="warning" />}></CustomButton >
  return <CustomButton float="right" onClick={(e) => onClick(e, id)} text={<UnfoldMoreIcon />}></CustomButton>
}

const GroupIcon = ({ id, grouped, onClick }) => {
  if (grouped)
    return <CustomButton float="left" onClick={(e) => onClick(e, id)} text={<SocialDistance />}></CustomButton >
  return <CustomButton float="left" onClick={(e) => onClick(e, id)} text={<GroupWorkIcon />}></CustomButton >
}

export {
    GroupIcon,
    CollapseIcon
}
import * as React from 'react';
import moment from 'moment';
import { sortAndGroupList } from '../util';
import { FlexBox, ListHeader, ListContainer } from '../styles';
import GetCompleteList from '../components/CompleteList';
import { GroupIcon, CollapseIcon } from '../components/ButtonAndIcon';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';

function Week({
  list,
  group,
  postApi,
  timerOn,
  setTimerOn,
  minimize,
  hideDetail,
  addTaskToList,
  deleteItem,
  setItem,
  minimizeList,
  hideListDetail,
  groupTask,
  showForm,
  setShowForm,
}) {
  const navigate = useNavigate();
  let { week } = useParams();
  const footerProps = { addTaskToList, navigate, minimizeList, minimize, postApi, week, showForm, setShowForm };
  const weekTitle = (day) => {
    return moment().week(week).startOf('week').add(day, 'days').format("dddd - DD MMM");
  }

  return (
    <div className="app">
      <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
        {[0, 1, 2, 3, 4, 5, 6].map((day) => {
          return <ListContainer hide={minimize.week}>
            <ListHeader id="all" onClick={minimizeList} bg="#b3eff5">
              <GroupIcon id="all" grouped={group.week} onClick={groupTask} />
              {weekTitle(day)}
              <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
            </ListHeader>
            <GetCompleteList markCurrentItem={true} taskList={sortAndGroupList('week', list, group.week, { week, day })} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.week} />
          </ListContainer>
        })}
      </FlexBox>
      <Footer {...footerProps} />
    </div>
  );
}

export default Week;

import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import moment from 'moment';
const minuteEpoch = 60 * 1000;
const hourEpoch = minuteEpoch * 60;
const dayEpoch = 24 * hourEpoch;
const LOCALHOST = getUrl()

function getUrl() {
  if (window.location.host.includes('localhost')) {
    return `http://localhost:3000`;
  } else {
    return `https://${window.location.host}`;
  }
}

function useDeepEffect(effectFunc, deps) {
  // 1° Step
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    // 2° Step
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    // 3° Step
    if (isFirst.current || !isSame) {
      effectFunc();
    }
    // 4° Step
    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const getDaysLeftForDeadline = (dateTime) => {
  if (!dateTime) return 0;
  const timeLeft = new Date(dateTime).getTime() - new Date().getTime();
  const daysLeft = Math.ceil(timeLeft / dayEpoch);
  if ((timeLeft < 0) || (daysLeft < 2)) {
    return daysLeft;
  }
  return 0;
}

const checkWeightage = (taskA, taskB) => {
  if (taskB.weightage && taskA.weightage) {
    if ((taskB.weightage - taskA.weightage) == 0)
      return 0;
    return (taskB.weightage - taskA.weightage) > 0 ? 1 : -1;
  } else if (taskB.weightage) {
    return 1;
  } else if (taskA.weightage) {
    return -1;
  }
}

const checkWeightageAndTime = (taskA, taskB) => {
  const order = checkWeightage(taskA, taskB);
  if (order === 0) {
    return (taskB.timeFrame - taskA.timeFrame) > 0 ? -1 : 1;
  }
  return order;
}

const noDeadlineRanking = (taskA, taskB) => {
  if ((!taskA.finalDeadline) && (!taskB.finalDeadline)) {
    return checkWeightageAndTime(taskA, taskB);
  } else if (!taskA.finalDeadline) {
    return 1;
  }
  return -1;
}

const compareDealines = (taskA, taskB, aDeadline, bDeadline) => {
  const daysDiff = getDaysLeftForDeadline(bDeadline) - getDaysLeftForDeadline(aDeadline)
  if (daysDiff === 0) {
    return checkWeightage(taskA, taskB);
  }
  return daysDiff > 0 ? -1 : 1;
}

const compareTwoValues = (aWeightage, bWeightage) => {
  return (bWeightage - aWeightage) > 0 ? -1 : 1;
};

const sortBasedOnPriority = (taskA, taskB) => {
  if (taskA.timeFrame && (taskA.timeFrame <= 5) && taskB.timeFrame && (taskB.timeFrame <= 5)) {
    return checkWeightageAndTime(taskA, taskB);
  } else if (taskA.timeFrame && (taskA.timeFrame <= 5)) {
    return -1;
  } else if (taskB.timeFrame && (taskB.timeFrame <= 5)) {
    return 1;
  } else if ((!taskA.finalDeadline) || (!taskB.finalDeadline)) {
    return noDeadlineRanking(taskA, taskB);
  } else if (taskA.finalDeadline && taskB.finalDeadline) {
    return compareDealines(taskA, taskB, taskA.finalDeadline, taskB.finalDeadline);
  } else if (taskA.tempDeadline && taskB.tempDeadline) {
    return compareDealines(taskA, taskB, taskA.tempDeadline, taskB.tempDeadline);
  } else if (taskB.weightage && taskA.weightage) {
    return compareTwoValues(taskA.weightage, taskB.weightage);
  } else if (taskB.timeFrame && taskA.timeFrame) {
    return compareTwoValues(taskA.timeFrame, taskB.timeFrame);
  }
  return 1;
}

const groupTaskList = (list) => {
  const s = new Set();
  let groupedList = [];
  list.forEach(tsk => tsk.parentTag && s.add(tsk.parentTag));

  Array.from(s).forEach((parentTag) => {
    const subTasks = list.filter(tsk => tsk.parentTag === parentTag);
    groupedList = [...groupedList, ...subTasks];
  })
  return [...groupedList, ...list.filter(item => !item.parentTag)];
}

const markFirst = (list) => {
  const occur = {};
  return list.map((tsk) => {
    if (tsk.parentTag && !occur[tsk.parentTag]) {
      occur[tsk.parentTag] = true;
      return { ...tsk, firstTask: true };
    }
    return { ...tsk, firstTask: false };
  });
}

const resetFirst = (list) => {
  return list.map((tsk) => {
    return { ...tsk, firstTask: false };
  });
}

const sortBasedOnRelativeOrder = (taskA, taskB) => {
  const orderA = taskA.relativeOrder ? Number(taskA.relativeOrder) : Number.MAX_SAFE_INTEGER;
  const orderB = taskB.relativeOrder ? Number(taskB.relativeOrder) : Number.MAX_SAFE_INTEGER;
  if (orderA > orderB) return 1;
  else if (orderA < orderB) return -1;
  return 0;
}

const isQuickCheckList = (item) => {
  return item.name && item.name.toUpperCase().includes('[QUICK]');
}

const sortAndGroupList = (type, list, grouped, filter) => {
  let newList = [];
  if (type === 'today') {
    newList = list.filter(item => !isQuickCheckList(item) && item.checked !== true && item.current === true).sort(sortBasedOnPriority).sort(sortBasedOnRelativeOrder);
  } else if (type === 'quick_checklist') {
    newList = list.filter(item => isQuickCheckList(item) && item.checked !== true).sort(sortBasedOnPriority).sort(sortBasedOnRelativeOrder);
  } else if (type === 'done') {
    newList = list.filter(item => item.checked === true).sort(sortBasedOnPriority).sort(sortBasedOnRelativeOrder);;
  } else if (type === 'follow') {
    newList = list.filter(item => !isQuickCheckList(item) && item.follow === true && item.checked !== true).sort(sortBasedOnPriority).sort(sortBasedOnRelativeOrder);;
  } else if (type === 'backlog') {
    newList = list.filter(item => item.current !== true && item.checked !== true && item.follow !== true).sort(sortBasedOnPriority).sort(sortBasedOnRelativeOrder);;
  } else if (type === 'all') {
    newList = list.filter((item) => (item.name || '').toLowerCase().includes((filter.name || '').trim().toLowerCase())).sort(sortBasedOnPriority);
  } else if (type === 'month') {
    newList = list.filter(item => {
      let toInclude = false;
      if (item.tempDeadline) {
        toInclude = toInclude || ((new Date(item.tempDeadline).getMonth() + 1) == filter.month);
      }
      if (item.finalDeadline) {
        toInclude = toInclude || ((new Date(item.finalDeadline).getMonth() + 1) == filter.month);
      }
      return toInclude;
    }).sort(sortBasedOnPriority);
  } else if (type === 'week') {
    newList = list.filter(item => {
      let date = moment().week(filter.week).startOf('week').add(filter.day, 'days').format('DD');
      let month = moment().week(filter.week).startOf('week').add(filter.day, 'days').format('MM');
      let tempDate = moment(item.tempDeadline).format('DD');
      let tempMonth = moment(item.tempDeadline).format('MM');
      let finalDate = moment(item.finalDeadline).format('DD');
      let finalMonth = moment(item.finalDeadline).format('MM');
      let toInclude = false;
      if (item.tempDeadline) {
        toInclude = toInclude || ((date == tempDate) && (tempMonth == month));
      }
      if (item.finalDeadline) {
        toInclude = toInclude || ((date == finalDate) && (finalMonth == month));
      }
      return toInclude;
    }).sort(sortBasedOnPriority);
  } else {
    newList = list.sort(sortBasedOnPriority);
  }

  const initialList = grouped ? groupTaskList(markFirst(newList)) : resetFirst(newList);
  if (isPage(pageType.SOMEDAY)) {
    return initialList.filter((task) => !isArchived(task) && isMarkedSomeday(task));
  } else if (isPage(pageType.ARCHIVED)) {
    return initialList.filter((task) => isArchived(task));
  } else if (isPage(pageType.MASTER) || isPage(pageType.MONTHLY) || isPage(pageType.WEEKLY)) {
    return initialList.filter((task) => !isArchived(task) && (!isMarkedSomeday(task)));
  } else {
    return initialList.filter((task) => (!isArchived(task)) && (!isMarkedMaster(task)) && (!isMarkedSomeday(task)));
  }
}

const pageType = {
  MASTER: 'MASTER',
  CURRENT: 'CURRENT',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  ARCHIVED: 'ARCHIVED',
  SOMEDAY: 'SOMEDAY'
}

const isMarkedMaster = (task) => {
  return task.page === pageType.MASTER;
}

const isArchived = (task) => {
  return task.archived === true;
}

const isMarkedCurrent = (task) => {
  return task.page === pageType.CURRENT;
}

const isMarkedSomeday = (task) => {
  return task.page === pageType.SOMEDAY;
}

const isPage = (pT) => {
  const path = window.location.pathname;
  if (pT === pageType.SOMEDAY && path.includes('someday-list')) {
    return true;
  } else if (pT === pageType.ARCHIVED && path.includes('archived-list')) {
    return true;
  } else if (pT === pageType.MASTER && path.includes('master-list')) {
    return true;
  } else if (pT === pageType.MONTHLY && path.includes('month-list')) {
    return true;
  } else if (pT === pageType.WEEKLY && path.includes('week-list')) {
    return true;
  } else if (pT === pageType.CURRENT && path === '/') {
    return true;
  }
  return false;
}

const getPageType = () => {
  const path = window.location.pathname;
  if (path.includes('someday-list')) {
    return pageType.SOMEDAY;
  } else if (path.includes('archived-list')) {
    return pageType.ARCHIVED;
  } else if (path.includes('master-list')) {
    return pageType.MASTER;
  } else if (path.includes('month-list')) {
    return pageType.MONTHLY;
  } else if (path.includes('week-list')) {
    return pageType.WEEKLY;
  } else if (path === '/') {
    return pageType.CURRENT;
  }
  return null;
}

const getTaskPageType = () => {
  const path = window.location.pathname;
  if (path.includes('someday-list')) {
    return pageType.SOMEDAY;
  } else if (path.includes('archived-list')) {
    return pageType.MASTER;
  } else if (path.includes('master-list')) {
    return pageType.MASTER;
  } else if (path.includes('month-list')) {
    return pageType.MASTER;
  } else if (path.includes('week-list')) {
    return pageType.MASTER;
  } else if (path === '/') {
    return pageType.CURRENT;
  }
  return null;
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const getPriorityText = (wt) => {
  if (wt <= 1) return 'Very low'
  else if (wt <= 3) return 'Low'
  else if (wt <= 5) return 'Medium'
  else if (wt <= 7) return 'High'
  else if (wt <= 9) return 'Very High'
  else if (wt == 10) return 'Blocked'
}

const getPriorityColor = (wt) => {
  if (wt <= 1) return 'Very low'
  else if (wt <= 3) return 'Low'
  else if (wt <= 5) return 'Medium'
  else if (wt <= 7) return 'High'
  else if (wt <= 9) return 'Very High'
  else if (wt == 10) return 'Blocked'
}

const isBacklog = (item) => {
  return !item.current && !item.checked && !item.follow;
}

const isCurrent = (item) => {
  return item.current;
}

const isFollow = (item) => {
  return item.follow;
}

const isDone = (item) => {
  return item.checked;
}

const groupColorMapping = {};
const getGroupColor = (parentTag) => {
  if (!groupColorMapping[parentTag]) {
    groupColorMapping[parentTag] = getRandomColor();
  }
  return groupColorMapping[parentTag];
}

const getFirstAndLastDayOfWeek = (dateObj) => {
  let firstDay = moment(dateObj).startOf('week').toDate();
  let lastDay = moment(dateObj).endOf('week').toDate();
  return { firstDay, lastDay }
}

const DEADLINE = {
  INITIAL: 'INITIAL',
  FINAL: 'FINAL'
}

const crossedDeadline = (task) => {
  const today = moment().valueOf();
  if (task.finalDeadline) {
    if (moment(task.finalDeadline).valueOf() < today) {
      return DEADLINE.FINAL;
    }
  }

  if (task.tempDeadline) {
    if (moment(task.tempDeadline).valueOf() < today) {
      return DEADLINE.INITIAL;
    }
  }

  return false;
}

const getTaskColor = (task, markCurrentItem, checked) => {
  if (!checked) {
    const deadlineType = crossedDeadline(task)
    if (deadlineType === DEADLINE.FINAL) {
      return '#efa4a4'
    } else if (deadlineType === DEADLINE.INITIAL) {
      return '#ffda00'
    }
  } else if (checked) {
    return 'lightgrey';
  }

  const markCurrent = (markCurrentItem && isMarkedCurrent(task));
  if (markCurrent) {
    return '#ccecff'
  }
}

const ITEM_TYPE = {
  ORDER: 'ORDER',
  PRIORITY: 'PRIORITY',
  TIME_FRAME: 'TIME_FRAME'
}

const getLocalStore = () => {
  const { localStorage } = window;

  const persist = (data) => {
    localStorage.setItem('data', JSON.stringify(data));
  }

  const getPersist = () => {
    const data = localStorage.getItem('data') || JSON.stringify({});
    return JSON.parse(data);
  }

  return {
    persist,
    getPersist
  }
}

export {
  LOCALHOST,
  useDeepEffect,
  useDebounce,
  sortBasedOnPriority,
  groupTaskList,
  sortAndGroupList,
  getRandomColor,
  getPriorityText,
  getPriorityColor,
  isBacklog,
  isCurrent,
  isFollow,
  isDone,
  getGroupColor,
  pageType,
  isMarkedMaster,
  isPage,
  getFirstAndLastDayOfWeek,
  isMarkedCurrent,
  crossedDeadline,
  getTaskColor,
  DEADLINE,
  getPageType,
  getTaskPageType,
  ITEM_TYPE,
  getLocalStore
}
import * as React from 'react';
import CustomListItem from './CustomListItem';
import { ListHeader } from '../styles';

const GetCompleteList = ({ markCurrentItem, taskList, setItem, setTimerOn, hideDetail, deleteItem, grouped }) => {
    if (taskList.length === 0) {
      return <ListHeader jc="center" bg="white" color="#2d2b2a">
        This list is empty
      </ListHeader>
    }
    return taskList.map(item =>
      <CustomListItem
        markCurrentItem={markCurrentItem}
        item={item}
        setItem={setItem}
        setTimerOn={setTimerOn}
        hideDetail={hideDetail}
        deleteItem={deleteItem}
        grouped={grouped}
      />
    )
}

export default GetCompleteList;
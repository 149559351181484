import * as React from 'react';
import moment from 'moment';
import { CreateTaskForm, MinimizeView, CustomButton, ListHeader } from '../styles';
import { isPage, pageType } from '../util';

const Footer = ({
    addTaskToList, navigate, minimizeList, minimize, postApi, month, week, showForm, setShowForm
}) => {

    const nextMonth = () => {
        const m = moment().month(month).add(1, 'M').month()
        return m === 0 ? 12 : m;
    }

    const prevMonth = () => {
        const m = moment().month(month).subtract(1, 'M').month()
        return m === 0 ? 12 : m;
    }

    return <>
        <MinimizeView>
            {minimize.today && <ListHeader id="today" onClick={minimizeList} bg="#ddee90">Today's todo</ListHeader>}
            {minimize.follow && <ListHeader id="follow" onClick={minimizeList} bg="#ae0000" color="white">Follow-up todo</ListHeader>}
            {minimize.done && <ListHeader id="done" onClick={minimizeList}>Done todo</ListHeader>}
            {minimize.backlog && <ListHeader id="backlog" bg="#90c0ee" onClick={minimizeList}>Backlog todo</ListHeader>}
        </MinimizeView>
        <CreateTaskForm onSave={addTaskToList} showForm={showForm} setShowForm={setShowForm} />
        <MinimizeView>
            {postApi.loading && <CustomButton maxWidth="75px" variant="contained" color="lightblue" text="Loading..." />}
            {postApi.success && <CustomButton maxWidth="75px" variant="contained" color="green" text="Saved!" />}
            {(postApi.success === false) && <CustomButton maxWidth="75px" variant="contained" color="red" text="Failed...!" />}
            {isPage(pageType.WEEKLY) && <CustomButton onClick={() => {
                navigate(`/week-list/${moment().week(week).add(1, 'week').week()}`)
            }}
                text={"Next Week"}
                maxWidth="120px"
            />}
            {isPage(pageType.WEEKLY) && <CustomButton onClick={() => {
                navigate(`/week-list/${moment().week(week).subtract(1, 'week').week()}`)
            }}
                text={"Prev Week"}
                maxWidth="120px"
            />}
            <CustomButton onClick={() => {
                navigate(`/week-list/${moment().format('w')}`)
            }}
                text={"Week View"}
                maxWidth="120px"
            />
            {isPage(pageType.MONTHLY) && <CustomButton onClick={() => {
                navigate(`/month-list/${nextMonth()}`)
            }}
                text={"Next Month"}
                maxWidth="120px"
            />}
            {isPage(pageType.MONTHLY) && <CustomButton onClick={() => {
                navigate(`/month-list/${prevMonth()}`)
            }}
                text={"Prev Month"}
                maxWidth="120px"
            />}
            <CustomButton onClick={() => {
                navigate(`/month-list/${moment().month() + 1}`)
            }}
                text={"Month View"}
                maxWidth="120px"
            />
            <CustomButton onClick={() => {
                if (isPage(pageType.MASTER)) {
                    navigate('/')
                } else {
                    navigate('/master-list')
                }
            }}
                text={isPage(pageType.MASTER) ? "Current list" : "Master list"}
                maxWidth="120px"
            />
            <CustomButton onClick={() => {
                navigate('/archived-list')
            }}
                text={"Archived list"}
                maxWidth="120px"
            />
            <CustomButton onClick={() => {
                navigate('/someday-list')
            }}
                text={"Someday list"}
                maxWidth="120px"
            />
            <CustomButton onClick={() => setShowForm(!showForm)} text="Show Form" maxWidth="100px" />
        </MinimizeView>
    </>
}

export default Footer;
import * as React from 'react';
import { sortAndGroupList } from '../util';
import { FlexBox, ListHeader, ListContainer, getReadableTimeFrame, VerticalSpacer } from '../styles';
import GetCompleteList from '../components/CompleteList';
import { GroupIcon, CollapseIcon } from '../components/ButtonAndIcon';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function MainPage({
  list,
  group,
  postApi,
  timerOn,
  setTimerOn,
  minimize,
  hideDetail,
  addTaskToList,
  pageDate,
  changeDate,
  deleteItem,
  setItem,
  minimizeList,
  hideListDetail,
  groupTask,
  saveList,
  getList,
  showForm,
  setShowForm,
}) {
  const navigate = useNavigate();

  const backlogList = sortAndGroupList('backlog', list, group.backlog);
  const doneList = sortAndGroupList('done', list, group.done);
  const currentList = sortAndGroupList('today', list, group.today);
  const quickChecklist = sortAndGroupList('quick_checklist', list, group.today);
  const followList = sortAndGroupList('follow', list, group.follow);

  const totalTodayTime = getReadableTimeFrame(currentList.reduce((total, t) => (Number(t.timeFrame) + total), 0));
  const totalQuickChecklistTime = getReadableTimeFrame(quickChecklist.reduce((total, t) => (Number(t.timeFrame) + total), 0));
  const totalBacklogTime = getReadableTimeFrame(backlogList.reduce((total, t) => (Number(t.timeFrame) + total), 0));
  const totalFollowTime = getReadableTimeFrame(followList.reduce((total, t) => (Number(t.timeFrame) + total), 0));
  const totalDoneTime = getReadableTimeFrame(doneList.reduce((total, t) => (Number(t.timeFrame) + total), 0));
  const footerProps = { addTaskToList, navigate, minimizeList, minimize, postApi, showForm, setShowForm };

  return (
    <div className="App">
      <VerticalSpacer space="2%">
        <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
          <ListContainer hide={minimize.today}>
            <ListHeader id="all" onClick={minimizeList} bg="lightgreen">
              <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
              Quick checklist ({totalQuickChecklistTime})
              <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
            </ListHeader>
            <GetCompleteList key="all" taskList={quickChecklist} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.today} />
          </ListContainer>
          <ListContainer hide={minimize.today}>
            <ListHeader id="all" onClick={minimizeList} bg="wheat">
              <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
              Today's todo ({totalTodayTime})
              <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
            </ListHeader>
            <GetCompleteList key="all" taskList={currentList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.today} />
          </ListContainer>
          <ListContainer hide={minimize.follow}>
            <ListHeader id="all" onClick={minimizeList} bg="#ae0000" color="white">
              <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
              Follow-up todo ({totalFollowTime})
              <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
            </ListHeader>
            <GetCompleteList key="follow" taskList={followList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.follow} />
          </ListContainer>
        </FlexBox>
      </VerticalSpacer>
      <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
        <ListContainer hide={minimize.done}>
          <ListHeader id="all" onClick={minimizeList}>
            <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
            Done todo ({totalDoneTime})
            <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
          </ListHeader>
          <GetCompleteList key="done" taskList={doneList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.done} />
        </ListContainer>
        <ListContainer hide={minimize.backlog}>
          <ListHeader id="all" bg="#90c0ee" onClick={minimizeList}>
            <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
            Backlog todo ({totalBacklogTime})
            <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
          </ListHeader>
          <GetCompleteList key="backlog" taskList={backlogList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.backlog} />
        </ListContainer>
      </FlexBox>
      <Footer {...footerProps} />
    </div>
  );
}

export default React.memo(MainPage);

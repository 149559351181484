import * as React from 'react';
import moment from 'moment';
import { sortAndGroupList } from '../util';
import { FlexBox, ListHeader, MasterContainer } from '../styles';
import GetCompleteList from '../components/CompleteList';
import { GroupIcon, CollapseIcon } from '../components/ButtonAndIcon';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';

function Master({
  list,
  group,
  postApi,
  timerOn,
  setTimerOn,
  minimize,
  hideDetail,
  addTaskToList,
  deleteItem,
  setItem,
  minimizeList,
  hideListDetail,
  groupTask,
  saveList,
  showForm,
  setShowForm,
}) {
  const navigate = useNavigate();
  let { month } = useParams();

  const allList = sortAndGroupList('month', list, group.all, { month });
  const footerProps = { addTaskToList, navigate, minimizeList, minimize, postApi, month, showForm, setShowForm };

  return (
    <div className="app">
      <ListHeader id="all" onClick={minimizeList} bg="#ff7300" color="white">
        <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
            Month's todo ({moment().month(Number(month) - 1).format("MMMM")})
        <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
      </ListHeader>
      <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
        <GetCompleteList markCurrentItem={true} taskList={allList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.all} />
      </FlexBox>
      <Footer {...footerProps} />
    </div>
  );
}

export default Master;
